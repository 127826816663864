jQuery(document).ready(function(){
    jQuery('.mble-nav-bar').click(function(){
        jQuery('.mble-nav-bar-item').slideToggle();
    });

    jQuery('#menuCollapseOne').collapse("hide");
    jQuery('#menuCollapseTwo').collapse("hide");
    jQuery('#menuCollapseThree').collapse("hide");
});

window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s= d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");$.src="//v2.zopim.com/?2uA6nML9LOQDsspIPO45MG5BlhEcuPa3";z.t=+new Date;$.type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
